import { css } from 'styled-components';

import { PricingCard } from '@shared/interfaces/pricing-card';
import { PricingPlan } from '@shared/interfaces/pricing-plan';
import { Subscription } from '@shared/interfaces/subscription';
import { SubscriptionInfo } from '@shared/interfaces/subscription-info';
import { _getSubscriptionIntervalDescription } from '../subscription/functions';

export function _getCards(subscriptions: Subscription[] = []): PricingPlan[] {
  let plans: PricingPlan[] = [];

  if (subscriptions.length) {
    subscriptions.forEach(subscription => {
      plans = [
        ...plans,
        {
          id: subscription.id,
          name: subscription.name,
          bundle: subscription.bundle,
          interval: subscription.interval,
          card: subscription.card,
          recommended: subscription.recommended,
          is_purchased: subscription.is_purchased,
          slug: subscription.slug,
        },
      ];
    });
  }

  return plans;
}

export function _getInfoForSubscription(
  subscription: Partial<Subscription>,
  prices: PricingCard[]
): SubscriptionInfo {
  const { selected } = prices.find(
    price => price.bundle === subscription.bundle
  );
  const interval = _getSubscriptionIntervalDescription(subscription.interval);

  return {
    title: interval,
    saleDiscountType: selected.type,
    price: selected.price,
    regPrice: selected.regPrice,
  };
}

export const getStyle = (
  size: string | number,
  style: string,
  unit: string = ''
): string => {
  if (typeof size !== 'undefined') {
    return `${style}: ${size}${unit};`;
  }
};

export const priceFormat = (symbol: string, price: number): string => {
  return `${symbol}${price?.toFixed(2)}`;
};

export const setRowGutter = (childComp, gutter: number) => {
  return css`
    margin-right: -${gutter}rem;
    margin-left: -${gutter}rem;

    > ${childComp} {
      padding-left: ${gutter}rem;
      padding-right: ${gutter}rem;
    }
  `;
};

export function getTitlePricingPage(
  mainPageTitles,
  recommendedPlan,
  salePercent,
  hasCurrentSale,
  saleTitle,
  affiliate,
  hasCompleteHeart,
  showCourseTitle
) {
  let title = mainPageTitles.default;

  if (recommendedPlan) {
    title = mainPageTitles[recommendedPlan.bundle] || title;
  }

  if (hasCurrentSale && saleTitle) {
    title = saleTitle;
  }

  if (affiliate && salePercent) {
    title = `Additional discount unlocked: Up to ${salePercent}% off your first year`;
  }

  if (hasCompleteHeart) {
    title =
      'Access all the features of Complete Heart with a <strong>Complete Anatomy</strong> license';
  }

  if (showCourseTitle) {
    title =
      '<strong>Unlock all Courses with a Complete Anatomy subscription</strong>';
  }

  return title;
}

export const getTitlePricingPageNew = (
  recommendedPlan: PricingPlan,
  affiliate: number,
  salePercent: string,
  hasCompleteHeart: boolean,
  showCourseTitle: boolean
): string => {
  let title = 'default';

  if (recommendedPlan) {
    title = recommendedPlan.bundle.replace('com.3D4Medical.CA.', '');
  }

  if (affiliate && salePercent) {
    title = 'affiliate';
  }

  if (hasCompleteHeart) {
    title = 'completeHeart';
  }

  if (showCourseTitle) {
    title = 'courseTitle';
  }

  return title;
};
